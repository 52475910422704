import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image';
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby';
import { fluidImage400, fluidImageWide } from '../fragments';
import Helmet from 'react-helmet'
const AngelInvestor = () => (
  <StaticQuery
    query={graphql`
          query AngelInvestorQuery {            
            stepImg: file( relativePath: { eq: "kazuend-19SC2oaVZW0-unsplash.jpg"} ){
              ...fluidImageWide
            }
          }
        `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Tobias Meixner - Projects</title>
        </Helmet>
        <Img
          fluid={data.stepImg.childImageSharp.fluid}
          style={{
            // display: 'inline',
            // width: '50%',
            height: '235px',
            margin: 0,
            objectFit: 'cover'
          }} alt="Forest" />
        <div className={"quote"}>
        Someone is sitting in the shade of a tree today because someone planted a tree a long time ago.
      <span>Warren Buffett</span>
        </div>
        <h1>Investments</h1>
        <p>
          As angel investor, I invest primarly into SaaS (B2B), developer tooling/experience and platforms, ideally where I can also support sharing my experience, advise and give feedback where needed.
        </p>
        <p>
          If you are raising for your early stage startup/idea and need help to get it off the ground - reach out <Link
            style={{
              textDecoration: 'underline',
            }}
            to={'/contact'}
          >
            here
          </Link>.
        </p>
        <ul className="projectList">
          {
            [
              // {
              //   src: data.hubqlImg.childImageSharp.fluid,
              //   title: 'Hubql',
              //   position: 'Co-Founder',
              //   link: 'https://www.hubql.com'
              // },
              // {
              //   // src: data.graphQLBangkok.childImageSharp.fluid,
              //   title: 'Your project?',
              //   position: 'Contact me',
              //   link: '/contact'
              // },
              // {
              //   src: data.briklImg.childImageSharp.fluid,
              //   title: 'Brikl',
              //   position: 'Co-Founder & prev. CTO',
              //   link: 'https://www.brikl.com'
              // },
              // {
              //   src: data.designedImg.childImageSharp.fluid,
              //   title: 'Designed',
              //   position: 'Technical Advisor',
              //   link: 'https://designed.org/'
              // },
              // {
              //   src: data.graphQLBangkok.childImageSharp.fluid,
              //   title: 'GraphQL Bangkok Meetup',
              //   position: 'Organiser',
              //   link: 'https://www.meetup.com/GraphQL-Bangkok/'
              // },
              // {
              //   src: data.graphQLAsia.childImageSharp.fluid,
              //   title: 'GraphQL Asia Conference 2019',
              //   position: 'Co-Organiser',
              //   link: 'https://www.graphql-asia.org/'
              // },             
            ].map((photo, index) => {
              return (
                <li key={index + 'img'}>
                  <a target="_blank" rel="noopener noreferrer" href={photo.link} >
                    {photo.src ? <Img fluid={photo.src} alt={photo.title} /> : <span style={{
                      height: 80,display:'block',width:80
                    }}></span>}
                    <strong>
                      {photo.title}
                    </strong>
                    <span>
                      {photo.position}
                    </span>
                  </a>
                </li>
              )
            })
          }
        </ul>

      </Layout>
    )} />
)

export default AngelInvestor
